import React, { useState, useEffect } from "react";
import classes from "./PublicationCard.module.css";

import Modal from "../../ui/Modal/Modal";
import Button from "../../ui/Button/Button";
import axios from "axios";

const ModalPublicationCard = (props) => {
  const { publication, cancel, refresh } = props;

  const { id, title, description, date } = publication;
  const dateFormat = new Date(Number(date)).toLocaleDateString();

  const getPdf = () => {
    window.open(
      process.env.REACT_APP_API_URL + `/publications/pdf/${id}`,
      "_blank"
    );
  };

  const deletePublication = () => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      axios
        .delete(process.env.REACT_APP_API_URL + `/publications/${id}`, {
          headers: {
            authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // console.log(response);
          cancel();
          refresh();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("cancel");
    }
  };

  return (
    <div className={classes.cardContainer}>
      <h2>{title}</h2>
      <p className={classes.info}>
        <p>{dateFormat}</p>
      </p>
      <p className={classes.message}>{description}</p>
      <div className={classes.options}>
        <Button className={classes.cancelBtn} onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={getPdf}>View</Button>
        <img
          className={classes.trash}
          src="/img/icons/trash.svg"
          alt="delete"
          onClick={deletePublication}
        />
      </div>
    </div>
  );
};

// #########################################################################

const PublicationCard = (props) => {
  const { publication, refresh } = props;

  const { id, title, description, date } = publication;

  const dateFormat = new Date(Number(date)).toLocaleDateString();

  const [isVisible, setIsVisible] = useState(false);

  const [shortDesc, setShortDesc] = useState("");

  useEffect(() => {
    if (description.length > 250) {
      setShortDesc(description.substring(0, 250));
    } else {
      setShortDesc(description);
    }
  }, []);

  const openModal = () => {
    if (!isVisible) {
      setIsVisible(true);
    }
  };

  const cancel = () => {
    setIsVisible(false);
  };

  return (
    <div className={classes.container} onClick={openModal}>
      {isVisible && (
        <Modal cancel={cancel}>
          <ModalPublicationCard
            publication={publication}
            cancel={cancel}
            refresh={refresh}
          ></ModalPublicationCard>
        </Modal>
      )}
      <div className={classes.top}>
        <p className={classes.title}>{title}</p>
        <p className={classes.date}>{dateFormat}</p>
      </div>
      <p className={classes.description}>{shortDesc}</p>
    </div>
  );
};

export default PublicationCard;
