import { createSlice } from "@reduxjs/toolkit";

const initialLoggedIn = {
  loggedIn: false,
};

const LogInSlice = createSlice({
  name: "User",
  initialState: initialLoggedIn,
  reducers: {
    logIn(state) {
      state.loggedIn = true;
    },
    logOut(state) {
      state.loggedIn = false;
    },
  },
});

export const LogInActions = LogInSlice.actions;
export default LogInSlice.reducer;
