import React, { useState, useEffect } from "react";
import classes from "./GalleryList.module.css";

import ImageComponent from "./ImageComponent/ImageComponent";
import VideoComponent from "./VideoComponent/VideoComponent";
import Button from "../ui/Button/Button";
import Modal from "../ui/Modal/Modal";
import axios from "axios";

import ModalAddImageCard from "./Modals/ModalAddImageCard";
import ModalAddVideoCard from "./Modals/ModalAddVideoCard";
import ModalEditCollectionCard from "./Modals/ModalEditCollectionCard";

/*const ModalAddImageCard = (props) => {
  const { cancel, refresh, collection } = props;
  const { id } = collection;

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const [note, setNote] = useState("");
  const [noteColor, setNoteColor] = useState("green");
  const [inputColor, setInputColor] = useState("black");

  const getExtension = (fileName) => {
    var parts = fileName.split(".");
    return parts[parts.length - 1];
  };

  const saveFile = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const submit = () => {
    if (fileName === "") {
      console.log(fileName);
      setInputColor("red");
    }
    console.log(id);
    if (!id) {
      console.error("NO COLLECTION SELECTED");
      return;
    }

    if (!(fileName === "")) {
      setInputColor("black");
    }

    if (!(fileName === "")) {
      const fileData = new FormData();
      fileData.append("file", file);
      fileData.append("fileName", fileName);
      fileData.append("collectionId", id);

      const ext = getExtension(fileName);
      console.log("extension", ext);

      axios
        .post(process.env.REACT_APP_API_URL + "/images", fileData, {
          headers: {
            authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          //   console.log(response);
          setFile("");
          setFileName("");
          setNoteColor("green");
          setNote("Sent!");

          refresh();
          cancel();
        })
        .catch((error) => {
          setNote("ERROR: TRY AGAIN LATER");
          setNoteColor("red");
          console.log(error);
        });
    }
  };

  return (
    <div className={classes.cardContainer}>
      <h2>Add Image</h2>
      <input
        type="file"
        id="myFile"
        name="filename"
        accept="image/*"
        onChange={saveFile}
        style={{ color: inputColor }}
      />

      <div className={classes.options}>
        <Button className={classes.cancelBtn} onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit}>Add</Button>
        <h4 style={{ color: noteColor }}>{note}</h4>
      </div>
    </div>
  );
};*/


// const ModalAddVideoCard = (props) => {
//   const { cancel, refresh, collection } = props;
//   const { id } = collection;

//   const [file, setFile] = useState();
//   const [fileName, setFileName] = useState("");
//   const [thumbnail, setThumbnail] = useState();
//   const [thumbnailName, setThumbnailName] = useState("");

//   const [title, setTitle] = useState("");
//   const [tError, setTError] = useState(false);

//   const [note, setNote] = useState("");
//   const [noteColor, setNoteColor] = useState("green");
//   const [inputColor, setInputColor] = useState("black");
//   const [TinputColor, setTInputColor] = useState("black");

//   const [sending, setSending] = useState(false);

//   const getExtension = (fileName) => {
//     var parts = fileName.split(".");
//     return parts[parts.length - 1];
//   };

//   const saveFile = (e) => {
//     if (e.target.files.length === 0) {
//       setFileName("");
//       setFile(null);
//       return;
//     }
//     setFile(e.target.files[0]);
//     setFileName(e.target.files[0].name);
//   };
//   const saveThumbnail = (e) => {
//     if (e.target.files.length === 0) {
//       setThumbnailName("");
//       setThumbnail(null);
//       return;
//     }
//     setThumbnail(e.target.files[0]);
//     setThumbnailName(e.target.files[0].name);
//   };

//   const submit = () => {
//     if (fileName === "") {
//       console.log(fileName);
//       setInputColor("red");
//     }
//     if (thumbnailName === "") {
//       console.log(fileName);
//       setTInputColor("red");
//     }
//     if (title === "") {
//       setTError(true);
//     } else {
//       setTError(false);
//     }
//     console.log(id);
//     if (!id) {
//       console.error("NO COLLECTION SELECTED");
//       return;
//     }

//     if (!(fileName === "")) {
//       setInputColor("black");
//     }
//     if (!(thumbnailName === "")) {
//       setTInputColor("black");
//     }

//     if (!(fileName === "") && !(thumbnailName === "") && !(title === "")) {
//       const fileData = new FormData();
//       fileData.append("file", file);
//       fileData.append("fileName", fileName);
//       fileData.append("thumbnail", thumbnail);
//       fileData.append("thumbnailName", thumbnailName);
//       fileData.append("collectionId", id);
//       fileData.append("title", title);

//       const ext = getExtension(fileName);
//       console.log("extension", ext);
//       setNote("Uploading...");
//       setNoteColor("blue");
//       setSending(true);
//       axios
//         .post(process.env.REACT_APP_API_URL + "/videos", fileData,{
//           headers: {
//             'authorization': `bearer ${localStorage.getItem("token")}`
//           }
//         })
//         .then((response) => {
//           //   console.log(response);
//           setFile("");
//           setFileName("");
//           setNoteColor("green");
//           setNote("Sent!");

//           setSending(false);

//           refresh();
//           cancel();
//         })
//         .catch((error) => {
//           setNote("ERROR: TRY AGAIN LATER");
//           setNoteColor("red");
//           console.log(error);
//           setSending(false);
//         });
//     }
//   };

//   return (
//     <div className={classes.cardContainer}>
//       <h2>Add Video Title</h2>
//       <TextField
//         variant="outlined"
//         label="Title"
//         fullWidth
//         value={title}
//         error={tError}
//         onChange={(newValue) => setTitle(newValue.target.value)}
//       />
//       <h2>Add Thumbnail</h2>
//       <input
//         type="file"
//         id="myFile"
//         name="filename"
//         accept="image/*"
//         onChange={saveThumbnail}
//         style={{ color: TinputColor }}
//       />

//       <h2>Add Video</h2>
//       <input
//         type="file"
//         id="myFile"
//         name="filename"
//         accept="video/mp4"
//         onChange={saveFile}
//         style={{ color: inputColor }}
//       />

//       <div className={classes.options}>
//         {!sending && (
//           <>
//             <Button className={classes.cancelBtn} onClick={cancel}>
//               Cancel
//             </Button>
//             <Button onClick={submit}>Add</Button>
//           </>
//         )}
//         <h4 style={{ color: noteColor }}>{note}</h4>
//       </div>
//     </div>
//   );
// };


const GalleryList = (props) => {
  const { collection } = props;
  const { id, title } = collection;

  const [isAddImageModalVisible, setIsAddImageModalVidible] = useState(false);
  const [isAddVideoModalVisible, setIsAddVideoModalVidible] = useState(false);
  const [isEditCollectionModalVisible, setIsEditCollectionModalVisible] = useState(false);

  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [sendRequest, setSendRequest] = useState(true);

  useEffect(() => {
    if (sendRequest) {
      axios
        .get(process.env.REACT_APP_API_URL + `/images/${id}`)
        .then((response) => {
          // console.log("response", response.data);
          setSendRequest(false);
          setImages(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });

      axios
        .get(process.env.REACT_APP_API_URL + `/videos/${id}`)
        .then((response) => {
          // console.log("response", response.data);
          setSendRequest(false);
          setVideos(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });

      setSendRequest(false);
    }
  }, [sendRequest, collection]);

  useEffect(() => {
    setSendRequest(true);
  }, [collection]);

  const refresh = () => {
    console.log(sendRequest);
    setSendRequest(true);
  };

  const cancel = () => {
    setIsAddImageModalVidible(false);
    setIsAddVideoModalVidible(false);
    setIsEditCollectionModalVisible(false)
  };

  return (
    <div className={classes.container}>
      {isAddImageModalVisible && (
        <Modal cancel={cancel}>
          <ModalAddImageCard
            collection={collection}
            cancel={cancel}
            refresh={refresh}
          />
        </Modal>
      )}
      {isAddVideoModalVisible && (
        <Modal cancel={cancel}>
          <ModalAddVideoCard
            collection={collection}
            cancel={cancel}
            refresh={refresh}
          />
        </Modal>
      )}
      {isEditCollectionModalVisible && (
        <Modal cancel={cancel}>
          <ModalEditCollectionCard
            collection={collection}
            cancel={cancel}
            refresh={refresh}
          />
        </Modal>
      )}
      <div className={classes.options}>
        <h2>{title}</h2>
        <Button onClick={() => setIsAddImageModalVidible(true)}>
          Add Image
        </Button>
        <Button onClick={() => setIsAddVideoModalVidible(true)}>
          Add Video
        </Button>
        <Button onClick={() => setIsEditCollectionModalVisible(true)}>
          Edit Collection
        </Button>
      </div>
      <div className={classes.list}>
        {images
          .slice(0)
          //   .reverse()
          .map((image) => {
            return (
              <ImageComponent
                key={image.id}
                id={image.id}
                cancel={cancel}
                refresh={refresh}
              />
            );
          })}
      </div>
      <div className={classes.list}>
        {videos
          .slice(0)
          //   .reverse()
          .map((video) => {
            return (
              <VideoComponent key={video.id} id={video.id} refresh={refresh} />
            );
          })}
      </div>
    </div>
  );
};

export default GalleryList;
