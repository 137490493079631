import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";

const Modal = (props) => {
  const { children, cancel } = props;

  return ReactDOM.createPortal(
    <div className={classes.container}>
      <div className={classes.backdrop} onClick={cancel}></div>
      <div className={classes.content}>{children}</div>
    </div>,
    document.body
  );
};

export default Modal;
