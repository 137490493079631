import React from 'react'
import classes from "./Publications.module.css"

import NavBar from '../../NavBar/NavBar'
import PublicationsCardList from '../../PublicationsCardList/PublicationsCardList'

const Publications = () => {
  return (
    <div className={classes.container}>
      <NavBar active="publications" />
      <PublicationsCardList />
      <div id="portal"></div>

    </div>
  )
}

export default Publications
