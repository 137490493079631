import React, { useState } from "react";
import classes from "./ModalStyle.module.css";

import Button from "../../ui/Button/Button";
import axios from "axios";

const ModalAddImageCard = (props) => {
    const { cancel, refresh, collection } = props;
    const { id } = collection;
  
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
  
    const [note, setNote] = useState("");
    const [noteColor, setNoteColor] = useState("green");
    const [inputColor, setInputColor] = useState("black");
  
    const getExtension = (fileName) => {
      var parts = fileName.split(".");
      return parts[parts.length - 1];
    };
  
    const saveFile = (e) => {
      if (e.target.files.length === 0) {
        return;
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    };
  
    const submit = () => {
      if (fileName === "") {
        // console.log(fileName);
        setInputColor("red");
      }
      // console.log(id);
      if (!id) {
        console.error("NO COLLECTION SELECTED");
        return;
      }
  
      if (!(fileName === "")) {
        setInputColor("black");
      }
  
      if (!(fileName === "")) {
        const fileData = new FormData();
        fileData.append("file", file);
        fileData.append("fileName", fileName);
        fileData.append("collectionId", id);
  
        const ext = getExtension(fileName);
        // console.log("extension", ext);
  
        axios
          .post(process.env.REACT_APP_API_URL + "/images", fileData, {
            headers: {
              authorization: `bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            //   console.log(response);
            setFile("");
            setFileName("");
            setNoteColor("green");
            setNote("Sent!");
  
            refresh();
            cancel();
          })
          .catch((error) => {
            setNote("ERROR: TRY AGAIN LATER");
            setNoteColor("red");
            console.log(error);
          });
      }
    };
  
    return (
      <div className={classes.cardContainer}>
        <h2>Add Image</h2>
        <input
          type="file"
          id="myFile"
          name="filename"
          accept="image/*"
          onChange={saveFile}
          style={{ color: inputColor }}
        />
  
        <div className={classes.options}>
          <Button className={classes.cancelBtn} onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={submit}>Add</Button>
          <h4 style={{ color: noteColor }}>{note}</h4>
        </div>
      </div>
    );
  };

  export default ModalAddImageCard;