import React from "react";
import classes from "./Careers.module.css";

import NavBar from "../../NavBar/NavBar";
import CareersCardList from "../../CareersCardList/CareersCardList";

const Careers = () => {
  return (
    <div className={classes.container}>
      <NavBar active="careers" />
      <CareersCardList />
      
      <div id="portal"></div>
    </div>
  );
};

export default Careers;
