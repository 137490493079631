import React, { useEffect, useState, useRef } from "react";
import classes from "./VideoComponent.module.css";

import axios from "axios";

import Modal from "../../ui/Modal/Modal";
import { type } from "@testing-library/user-event/dist/type";

const VideoComponent = (props) => {
  const { id, refresh } = props;

  const [thumbnail, setThumbnail] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false)

  const videoRef = useRef(null)

  const getThumbnail = () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/videos/thumbnail/${id}`)
      .then((response) => {
        setThumbnail(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getThumbnail();
  }, []);


  const cancel = ()=>{
    setIsModalVisible(false)
  }

  const deleteVideo = () =>{
    if (window.confirm("Are you sure you want to delete this video?")) {
              axios
                .delete(process.env.REACT_APP_API_URL + `/videos/video/${id}`,{
                  headers: {
                    'authorization': `bearer ${localStorage.getItem("token")}`
                  }
                })
                .then((response) => {
                  // console.log(response);
                  cancel();
                  refresh();
                })
                .catch((error) => {
                  console.error(error);
                });
            } else {
              // console.log("cancel");
            }
  }


  return (
    <div className={classes.container}>
        {isModalVisible && <Modal cancel={cancel}>
            <video ref={videoRef}  controls autoPlay>
                <source src={process.env.REACT_APP_API_URL + `/videos/video/${id}`} type="video/mp4"></source>
            </video>
            
            <img 
                className={classes.trash}
                src="/img/icons/trash.svg"
                onClick={deleteVideo}
            />
        </Modal>}
      {thumbnail && (
        <img
          src={process.env.REACT_APP_API_URL + `/videos/thumbnail/${id}`}
          alt="img"
          className={classes.img}
          onClick={()=>{setIsModalVisible(true)}}
        />
      )}
      {thumbnail && (
        <img
          className={classes.play}
          //   onClick={deleteImage}
          src="/img/icons/play.png"
          onClick={()=>{setIsModalVisible(true)}}
        />
      )}
    </div>
  );
};

export default VideoComponent;
