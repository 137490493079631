import React, { useRef, useState } from "react";
import classes from "./SignIn.module.css";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LogInActions } from "../../../store/Slices/UserSlice";
import axios from "axios";
import TextField from "@mui/material/TextField";

import Button from "../../ui/Button/Button";

const SignIn = (props) => {
  const dispatch = useDispatch();

  const usernameRef = useRef();
  const passwordRef = useRef();
  const [errorHelperText, setErrorHelperText] = useState("");

  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const navigate = useNavigate();

  const logIn = () => {
    axios
      .post(process.env.REACT_APP_API_URL+"/users/login", {
        username: usernameRef.current.value, 
        password: passwordRef.current.value,
      })
      .then(function (response) {
        // console.log("Success",response)
        const userAccessToken = response.data.token;

        if (userAccessToken) {
          localStorage.setItem("token", userAccessToken);

          setUsernameError(false);
          setPasswordError(false);
          setErrorHelperText("");

          dispatch(LogInActions.logIn());

          navigate("/");
        }
      })
      .catch(function (error) {
        setUsernameError(true);
        setPasswordError(true);
        setErrorHelperText("incorrect username or password");
        console.log(error);
      });
  };

  return (
    <div className={classes.container}>
      <img
        src="/img/logoIcon.webp"
        alt="Civil Defence logoCaptioned"
        className={classes.logo}
      />
      <div className={classes.formContainer}>
        <h2 className={classes.formLabel}>Username</h2>
        <TextField
          label="username"
          variant="outlined"
          inputRef={usernameRef}
          error={usernameError}
          fullWidth
          sx={{
            backgroundColor: "white",
            marginBottom: "40px;",
          }}
        />

        <h2 className={classes.formLabel}>Password</h2>
        <TextField
          label="password"
          variant="outlined"
          type="password"
          error={passwordError}
          inputRef={passwordRef}
          fullWidth
          sx={{
            backgroundColor: "white",
          }}
        />
        <p className={classes.error}>{errorHelperText}</p>

        <div className={classes.btns}>
          <Button onClick={logIn}>Sign In</Button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
