import React from 'react'
import classes from "./ContactUs.module.css"

import NavBar from '../../NavBar/NavBar'
import ContactsCardsList from "../../ContactsCardsList/ContactsCardsList"

const ContactUs = () => {
  return (
    <div className={classes.container}>
      <NavBar active="contactus" />
      <ContactsCardsList />
      <div id="portal"></div>

    </div>
  )
}

export default ContactUs
