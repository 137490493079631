import React, { useEffect, useState } from "react";
import classes from "./ContactCard.module.css";

import Modal from "../../ui/Modal/Modal";
import Button from "../../ui/Button/Button";
import axios from "axios";

const ModalContactCard = (props) => {
  const { title, message, date, viewed, email, id, refresh, cancel } = props;

  const dateFormat = new Date(Number(date)).toLocaleDateString();

  const deleteContact = () => {
    if (window.confirm("Are you sure you want to delete this message?")) {
      axios
        .delete(process.env.REACT_APP_API_URL + `/contacts/${id}`, {
          headers: {
            authorization: `bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          // console.log(response);
          cancel();
          refresh();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("cancel");
    }
  };

  const markAsRead = () => {
    axios
      .put(process.env.REACT_APP_API_URL + `/contacts/`, {
        id: id,
        viewed: 1,
      },{
        headers: {
          'authorization': `bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        // console.log(response);
        cancel();
        refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const markAsUnread = () => {
    axios
      .put(process.env.REACT_APP_API_URL + `/contacts/`, {
        id: id,
        viewed: 0,
      },{
        headers: {
          'authorization': `bearer ${localStorage.getItem("token")}`
        }
      })
      .then((response) => {
        // console.log(response);
        cancel();
        refresh();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={classes.cardContainer}>
      {viewed === 0 && <div className={classes.viewed}></div>}
      <h2>{title}</h2>
      <div className={classes.info}>
        <p>{email}</p>
        <p>{dateFormat}</p>
      </div>
      <p className={classes.message}>{message}</p>

      <div className={classes.options}>
        <Button className={classes.cancelBtn} onClick={cancel}>
          Cancel
        </Button>
        {viewed === 0 && <Button onClick={markAsRead}>Mark As Read</Button>}
        {viewed === 1 && <Button onClick={markAsUnread}>Mark As Unread</Button>}
        <img
          className={classes.trash}
          src="/img/icons/trash.svg"
          alt="delete"
          onClick={deleteContact}
        />
      </div>
    </div>
  );
};

const ContactCard = (props) => {
  const { contact, refresh } = props;

  const { id, first, last, message, date, viewed, email } = contact;

  const [shortMsg, setShortMsg] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const dateFormat = new Date(Number(date)).toLocaleDateString();

  useEffect(() => {
    if (message.length > 250) {
      setShortMsg(message.substring(0, 250));
    } else {
      setShortMsg(message);
    }
  }, []);

  const openModal = () => {
    if (!isVisible) {
      setIsVisible(true);
    }
  };

  const cancel = () => {
    setIsVisible(false);
  };

  return (
    <div className={classes.container} onClick={openModal}>
      {isVisible && (
        <Modal cancel={cancel}>
          <ModalContactCard
            id={id}
            title={first + " " + last}
            message={message}
            date={date}
            viewed={viewed}
            email={email}
            refresh={refresh}
            cancel={cancel}
          ></ModalContactCard>
        </Modal>
      )}
      {viewed === 0 && <div className={classes.viewed}></div>}
      <div className={classes.top}>
        <p className={classes.title}>{first + " " + last}</p>
        <p className={classes.date}>{dateFormat}</p>
      </div>
      <p className={classes.description}>{shortMsg}...</p>
    </div>
  );
};

export default ContactCard;
