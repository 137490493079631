import React, { useRef } from "react";
import classes from "./Button.module.css";

const Button = (props) => {
  const { className, onClick,href, children } = props;

  const container = useRef(null)

  const onMouseOver = (event) => {
    let rect = container.current.getBoundingClientRect();
    let x = event.clientX - rect.left; //x position within the element.
    let y = event.clientY - rect.top; //y position within the element.

    
    container.current.style.setProperty("--width", `${100}vw`);
    container.current.style.setProperty("--top", `${y}px`);
    container.current.style.setProperty("--left", `${x}px`);

  };
  const onMouseOut = (event) => {
    // const container = document.getElementsByClassName(classes.container)[1];
    let rect = container.current.getBoundingClientRect();
    let x = event.clientX - rect.left; //x position within the element.
    let y = event.clientY - rect.top; //y position within the element.
    container.current.style.setProperty("--top", `${y}px`);
    container.current.style.setProperty("--left", `${x}px`);
    container.current.style.setProperty("--width", `0px`);
  };

  return (
    <button
      className={classes.container + " " + className}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      href={href}
      ref={container}
    >
      {children}
    </button>
  );
};
export default Button;
