import React from "react";
import classes from "./NavBar.module.css";

import { Link } from "react-router-dom";
import Button from "../ui/Button/Button";

const NavBar = (props) => {
  const { active } = props;
  return (
    <div className={classes.container}>
      <img src="/img/logoIcon.webp" alt="logo" className={classes.logo} />

      <div className={classes.navMenu}>
        {active === "contactus" ? (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link + " " + classes.active}
            to={"/contactus"}
          >
            Contact Us
          </Link>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link}
            to={"/contactus"}
          >
            Contact Us
          </Link>
        )}
        {active === "careers" ? (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link + " " + classes.active}
            to={"/careers"}
          >
            Careers
          </Link>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link}
            to={"/careers"}
          >
            Careers
          </Link>
        )}
        {active === "publications" ? (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link + " " + classes.active}
            to={"/publications"}
          >
            Publications
          </Link>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link}
            to={"/publications"}
          >
            Publications
          </Link>
        )}
        {active === "gallery" ? (
          <Link
            to={"/gallery"}
            style={{ textDecoration: "none" }}
            className={classes.link + " " + classes.active}
          >
            Gallery
          </Link>
        ) : (
          <Link
            style={{ textDecoration: "none" }}
            className={classes.link}
            to={"/gallery"}
          >
            Gallery
          </Link>
        )}
        <Link to="/signin">
          <Button>Log Out</Button>
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
