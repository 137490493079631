import React, {useState} from "react";
import classes from "./Gallery.module.css";

import NavBar from "../../NavBar/NavBar";
import CollectionsList from "../../CollectionsList/CollectionsList";
import GalleryList from "../../GalleryList/GalleryList";

const Gallery = () => {
  const [selectedCollection,setSelectedCollection] = useState()

  const selectCollection = (collection) =>{
    setSelectedCollection(collection)
  }
  const refresh = () => {

  };


  return (
    <div className={classes.container}>
      <NavBar active="gallery" />
      <div className={classes.main}>
        <CollectionsList selectCollection={selectCollection}  />
        {selectedCollection && <GalleryList collection={selectedCollection} refreshCollections={refresh} />}
      </div>

      <div id="portal"></div>
    </div>
  );
};
export default Gallery;
