import React, { useState, useEffect } from "react";
import classes from "./CareersCardList.module.css";

import CareersCard from "./CareersCard/CareersCard";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

import { useDispatch } from "react-redux";
import { LogInActions } from "../../store/Slices/UserSlice";


const CareersCardList = () => {
  const dispatch = useDispatch()

  const [careers, setCareers] = useState([]);
  const [checked, setChecked] = useState(false);
  const [sendRequest, setSendRequest] = useState(true);

  const refresh = () => {
    setSendRequest(true);
  };

  useEffect(() => {
    if (sendRequest) {
      axios
        .get(process.env.REACT_APP_API_URL + "/careers",{
          headers: {
            'authorization': `bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          // console.log("response", response.data);
          setCareers(response.data);
        })
        .catch((error) => {
          if(error.response.status === 401){
            dispatch(LogInActions.logOut());
          }
          
          console.error("error", error.response.status);
        });
      setSendRequest(false);
    }
  }, [sendRequest]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.container}>
      <div className={classes.options}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label="Unread"
        />
      </div>
      {careers
        .slice(0)
        .reverse()
        .filter((contact) => {
          if (checked) {
            return contact.viewed === 0;
          } else {
            return true;
          }
        })
        .map((career) => {
          return (
            <CareersCard key={career.id} career={career} refresh={refresh} />
          );
        })}
    </div>
  );
};

export default CareersCardList;
