import React, { useState } from "react";
import classes from "./ModalStyle.module.css";

import { TextField } from "@mui/material";
import Button from "../../ui/Button/Button";
import axios from "axios";

const ModalAddVideoCard = (props) => {
    const { cancel, refresh, collection } = props;
    const { id } = collection;
  
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [thumbnail, setThumbnail] = useState();
    const [thumbnailName, setThumbnailName] = useState("");
  
    const [title, setTitle] = useState("");
    const [tError, setTError] = useState(false);
  
    const [note, setNote] = useState("");
    const [noteColor, setNoteColor] = useState("green");
    const [inputColor, setInputColor] = useState("black");
    const [TinputColor, setTInputColor] = useState("black");
  
    const [sending, setSending] = useState(false);
  
    const getExtension = (fileName) => {
      var parts = fileName.split(".");
      return parts[parts.length - 1];
    };
  
    const saveFile = (e) => {
      if (e.target.files.length === 0) {
        setFileName("");
        setFile(null);
        return;
      }
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    };
    const saveThumbnail = (e) => {
      if (e.target.files.length === 0) {
        setThumbnailName("");
        setThumbnail(null);
        return;
      }
      setThumbnail(e.target.files[0]);
      setThumbnailName(e.target.files[0].name);
    };
  
    const submit = () => {
      if (fileName === "") {
        // console.log(fileName);
        setInputColor("red");
      }
      if (thumbnailName === "") {
        // console.log(fileName);
        setTInputColor("red");
      }
      if (title === "") {
        setTError(true);
      } else {
        setTError(false);
      }
      // console.log(id);
      if (!id) {
        console.error("NO COLLECTION SELECTED");
        return;
      }
  
      if (!(fileName === "")) {
        setInputColor("black");
      }
      if (!(thumbnailName === "")) {
        setTInputColor("black");
      }
  
      if (!(fileName === "") && !(thumbnailName === "") && !(title === "")) {
        const fileData = new FormData();
        fileData.append("file", file);
        fileData.append("fileName", fileName);
        fileData.append("thumbnail", thumbnail);
        fileData.append("thumbnailName", thumbnailName);
        fileData.append("collectionId", id);
        fileData.append("title", title);
  
        const ext = getExtension(fileName);
        // console.log("extension", ext);
        setNote("Uploading...");
        setNoteColor("blue");
        setSending(true);
        axios
          .post(process.env.REACT_APP_API_URL + "/videos", fileData,{
            headers: {
              'authorization': `bearer ${localStorage.getItem("token")}`
            }
          })
          .then((response) => {
            //   console.log(response);
            setFile("");
            setFileName("");
            setNoteColor("green");
            setNote("Sent!");
  
            setSending(false);
  
            refresh();
            cancel();
          })
          .catch((error) => {
            setNote("ERROR: TRY AGAIN LATER");
            setNoteColor("red");
            console.log(error);
            setSending(false);
          });
      }
    };
  
    return (
      <div className={classes.cardContainer}>
        <h2>Add Video Title</h2>
        <TextField
          variant="outlined"
          label="Title"
          fullWidth
          value={title}
          error={tError}
          onChange={(newValue) => setTitle(newValue.target.value)}
        />
        <h2>Add Thumbnail</h2>
        <input
          type="file"
          id="myFile"
          name="filename"
          accept="image/*"
          onChange={saveThumbnail}
          style={{ color: TinputColor }}
        />
  
        <h2>Add Video</h2>
        <input
          type="file"
          id="myFile"
          name="filename"
          accept="video/mp4"
          onChange={saveFile}
          style={{ color: inputColor }}
        />
  
        <div className={classes.options}>
          {!sending && (
            <>
              <Button className={classes.cancelBtn} onClick={cancel}>
                Cancel
              </Button>
              <Button onClick={submit}>Add</Button>
            </>
          )}
          <h4 style={{ color: noteColor }}>{note}</h4>
        </div>
      </div>
    );
  };

  export default ModalAddVideoCard;