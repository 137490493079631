import React, { useEffect, useState } from "react";
import classes from "./ContactsCardsList.module.css";

import ContactCard from "./ContactCard/ContactCard";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import axios from "axios";

const ContactsCardsList = () => {
  const [contacts, setContacts] = useState([]);
  const [checked, setChecked] = useState(false);
  const [sendRequest, setSendRequest] = useState(true);

  const refresh = () => {
    setSendRequest(true);
  };

  useEffect(() => {
    if (sendRequest) {
      axios
        .get(process.env.REACT_APP_API_URL + "/contacts",{
          headers: {
            'authorization': `bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          // console.log("response", response);
          setContacts(response.data);
        })
        .catch((error) => {
          console.error("error", error);
        });
      setSendRequest(false);
    }
  }, [sendRequest]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.container}>
      <div className={classes.options}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleChange} />}
          label="Unread"
        />
      </div>
      {contacts
        .slice(0)
        .reverse()
        .filter((contact) => {
          if (checked) {
            return contact.viewed === 0;
          } else {
            return true;
          }
        })
        .map((contact) => {
          return (
            <ContactCard key={contact.id} contact={contact} refresh={refresh} />
          );
        })}
    </div>
  );
};

export default ContactsCardsList;
