import React, { useEffect } from "react";
import classes from "./App.module.css";

import HomePage from "./componenets/pages/HomePage/HomePage";
import ContactUs from "./componenets/pages/ContactUs/ContactUs";
import Careers from "./componenets/pages/Careers/Careers";
import Publications from "./componenets/pages/Publications/Publications";
import Gallery from "./componenets/pages/Gallery/Gallery";

import { Route, Routes,useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



import SignIn from "./componenets/pages/SignIn/SignIn";



// require('dotenv').config()



function App() {
  const loggedIn = useSelector((state) => state.user.loggedIn);
  useEffect(() => {
    // console.log("LOGGED IN", loggedIn)
  },[loggedIn])

  return (
    <div className={classes.container}>
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/" element={loggedIn ? <HomePage /> : <SignIn />} />
        <Route path="/contactus" element={loggedIn ? <ContactUs /> : <SignIn />} />
        <Route path="/careers" element={loggedIn ? <Careers /> : <SignIn />} />
        <Route path="/publications" element={loggedIn ? <Publications /> : <SignIn />} />
        <Route path="/gallery" element={loggedIn ? <Gallery /> : <SignIn />} />
      </Routes>

    </div>
  );
}

export default App;
