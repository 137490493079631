import React, { useEffect, useState } from "react";
import classes from "./ImageComponent.module.css";

import axios from "axios";

const ImageComponent = (props) => {
  const { id, cancel, refresh } = props;

  const [image, setImage] = useState(null);

  const getIImage = () => {
    axios
      .get(process.env.REACT_APP_API_URL + `/images/image/${id}`)
      .then((response) => {
        setImage(response);
        // console.log(btoa(unescape(encodeURIComponent(response.data))))
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getIImage();
  }, []);

  const deleteImage = () => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      axios
        .delete(process.env.REACT_APP_API_URL + `/images/image/${id}`,{
          headers: {
            'authorization': `bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          // console.log(response);
          cancel();
          refresh();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("cancel");
    }
  };

  return (
    <div className={classes.container}>
      {image && (
        <img
          src={process.env.REACT_APP_API_URL + `/images/image/${id}`}
          alt="img"
          className={classes.img}
        />
      )}
      {image && <img className={classes.trash} onClick={deleteImage} src="/img/icons/trash.svg"/>}
    </div>
  );
};

export default ImageComponent;
