import React, { useState, useEffect } from "react";
import classes from "./ModalStyle.module.css";

import Button from "../../ui/Button/Button";

import { TextField } from "@mui/material";
import axios from "axios";

const ModalEditCollectionCard = (props) => {
  const { collection, cancel, refresh } = props;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [eTitle, setETitle] = useState(false);
  const [eDescription, setEDescription] = useState(false);

  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");

  const [note, setNote] = useState("");
  const [noteColor, setNoteColor] = useState("green");
  const [inputColor, setInputColor] = useState("black");

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setTitle(collection.title);
    setDescription(collection.description);
  }, []);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const getExtension = (fileName) => {
    var parts = fileName.split(".");
    return parts[parts.length - 1];
  };

  const saveFile = (e) => {
    if (e.target.files.length === 0) {
      return;
    }
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const submit = () => {
    if (title === "") {
      setETitle(true);
    }
    if (description === "") {
      setEDescription(true);
    }

    if (!(title === "")) {
      setETitle(false);
    }
    if (!(description === "")) {
      setEDescription(false);
    }
    if (!(fileName === "")) {
      setInputColor("black");
    }

    if (!(title === "") && !(description === "")) {
      if (isChecked) {
        if (fileName === "") {
          setInputColor("red");
          return; // Prevent submission if file is required and not provided
        }

        const fileData = new FormData();
        fileData.append("file", file);
        fileData.append("fileName", fileName);
        fileData.append("title", title);
        fileData.append("description", description);

        const ext = getExtension(fileName);
        // console.log("extension", ext);
        // console.log("fileData", fileData.getAll(fileName));

        // console.log("file Changed", fileName)
        axios
          .put(
            process.env.REACT_APP_API_URL + "/collections/" + collection.id,
            fileData,
            {
              headers: {
                authorization: `bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            // console.log(response);
            setTitle("");
            setDescription("");
            setFile("");
            setFileName("");
            setNoteColor("green");
            setNote("Sent!");

            refresh();
            cancel();
          })
          .catch((error) => {
            setNote("ERROR: TRY AGAIN LATER");
            setNoteColor("red");
            console.log(error);
          });
      } else {
        // No file change requested, send only title and description
        // console.log("no file change")
        axios
          .put(
            process.env.REACT_APP_API_URL + "/collections/" + collection.id,
            {
              title: title,
              description: description,
            },
            {
              headers: {
                authorization: `bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setTitle("");
            setDescription("");
            setNoteColor("green");
            setNote("Updated!");

            refresh();
            cancel();
          })
          .catch((error) => {
            setNote("ERROR: TRY AGAIN LATER");
            setNoteColor("red");
            console.log(error);
          });
      }
    }
  };

  return (
    <div className={classes.cardContainer}>
      <h2>Collection Title</h2>
      <TextField
        variant="outlined"
        label="Title"
        fullWidth
        value={title}
        error={eTitle}
        onChange={(newValue) => setTitle(newValue.target.value)}
      />

      <h2>Collection Description</h2>
      <TextField
        variant="outlined"
        label="Description"
        fullWidth
        multiline
        rows={5}
        value={description}
        error={eDescription}
        onChange={(newValue) => setDescription(newValue.target.value)}
      />

      <h2>Edit Collection Thumbnail</h2>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      />
      <label>Change Thumbnail</label>
      <br />
      <br />
      <input
        type="file"
        id="myFile"
        name="filename"
        accept="image/*"
        onChange={saveFile}
        style={{ color: inputColor }}
        disabled={!isChecked}
      />

      <div className={classes.options}>
        <Button className={classes.cancelBtn} onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit}>Edit</Button>
        <h4 style={{ color: noteColor }}>{note}</h4>
      </div>
    </div>
  );
};

export default ModalEditCollectionCard;
