import React, { useEffect, useState } from "react";
import classes from "./CollectionCard.module.css";

import Button from "../../ui/Button/Button";
import axios from "axios";

const CollectionCard = (props) => {
  const { collection, selectCollection, refresh } = props;
  const { id, title, description } = collection;

  const [image, setImage]=useState(null)


  const getThumbnail = ()=>{
    axios.get(process.env.REACT_APP_API_URL + `/collections/thumbnail/${id}`).then(response =>{

        setImage(response)
        // console.log(btoa(unescape(encodeURIComponent(response.data))))
    }).catch(error=>{
        console.log(error)
    })
  }

  useEffect(()=>{
    getThumbnail()
  },[])


  const deleteCollection=()=>{
    if (window.confirm("Are you sure you want to delete this Image?")) {
      axios
        .delete(process.env.REACT_APP_API_URL + `/collections/${id}`,{
          headers: {
            'authorization': `bearer ${localStorage.getItem("token")}`
          }
        })
        .then((response) => {
          // console.log(response);
          selectCollection(null)
          refresh();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("cancel");
    }
  }
  return (
    <div>
      <Button className={classes.collectionCard} onClick={()=>{selectCollection(collection)}}>
        {image && <img className={classes.img} src={process.env.REACT_APP_API_URL + `/collections/thumbnail/${id}`} alt="img" />}
        <h2>{title}</h2>
        {image && <img className={classes.trash} onClick={deleteCollection} src="/img/icons/trash.svg"/>}
      </Button>
    </div>
  );
};
export default CollectionCard;
